import React from 'react';
import { FiLinkedin, FiGithub, FiMail } from 'react-icons/fi';
import styled from 'styled-components';

const TheFooter = styled.div`
  width: 100%;
  color: var(--brown);

  h5 {
    margin: 0;
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    padding: 1rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--brown);
    transition: 0.5s color ease-in-out, 0.25s transform;

    &:hover {
      cursor: pointer;
      color: var(--browner);
      transform: scale(1.25);
    }
  }
`;

export default function Footer() {
  return (
    <TheFooter>
      <Div>
        <a
          href="https://www.linkedin.com/in/mateofilip/"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="LinkedIn"
        >
          <FiLinkedin />
        </a>
        <a href="mailto:mateofernandofilip@gmail.com" aria-label="Mail">
          <FiMail />
        </a>
        <a
          href="https://github.com/mateofilip"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="GitHub"
        >
          <FiGithub />
        </a>
      </Div>

      <h5>Designed &amp; Built by Mateo Filip</h5>
    </TheFooter>
  );
}
