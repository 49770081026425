import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledLoader = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;

  div {
    border-radius: 50%;
    animation: pulserr 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    @keyframes pulserr {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
    img {
      width: 10rem;
      border-radius: 50%;
    }

    h1 {
      margin: 0.5rem 0;
      color: var(--grey);
    }
  }
`;

export default function Loader() {
  return (
    <StyledLoader className="animate__animated animate__fadeIn animate_delay-5s">
      <div>
        <StaticImage
          src="../images/avatarIcon.png"
          alt="Icon"
          placeholder="tracedSVG"
          loading="eager"
        />
        <h1>Mateo Filip</h1>
      </div>
    </StyledLoader>
  );
}
