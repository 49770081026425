import { createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle`


* {
  font-family: 'Inter', sans-serif;
}

:root {
  --background: #f9f4ef;
  --black: #020826;
  --grey: #2b2c34;
  --brown: #8c7851;
   --browner: #716040;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background-color: var(--brown);
  border: 3px solid var(--background);
  border-radius: 10px;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  font-weight: 600;
  text-align: center;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

p {
  color: var(--grey);
}

h1, h2, h3 {
  color: var(--black);
}

.button {
  color: var(--background);
  background-color: var(--brown);
  padding: 1rem 2rem;
  border: none;
  font-size: 1em;
  font-weight: 600;
  border-radius: 10px;
  transition: 0.5s background-color ease-in-out;

  &:hover {
    background-color: var(--browner);
    cursor: pointer;
  }
  }
`;
