import React from 'react';
import { GlobalStyle } from '../Styles/GlobalStyles';
import FontStyles from '../Styles/fonts.js';
import Layout from '../components/Layout';

export default function IndexPage() {
  return (
    <>
      <FontStyles />
      <GlobalStyle />
      <Layout></Layout>
    </>
  );
}
