import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FiMenu, FiX } from 'react-icons/fi';

const Navbar = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    position: relative;
    font-size: 2em;
    color: var(--brown);
    z-index: 500;

    @media only screen and (min-width: 1080px) {
      display: none;
    }
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  color: var(--brown);
  transition: color 0.5s ease-in-out;
  margin: 0.5rem 0;

  img {
    border-radius: 50%;
  }

  &:hover {
    animation: pulseName 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    @keyframes pulseName {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.75;
      }
    }
  }

  h3 {
    display: none;
    margin-left: 1rem;

    @media only screen and (min-width: 768px) {
      display: block;
    }
  }
`;

const Ul = styled.ul`
  display: none;

  @media only screen and (min-width: 1080px) {
    display: block;
  }
`;

const Li = styled.li`
  display: inline;
  padding: 1rem;
`;

const A = styled.a`
  color: var(--black);
  text-decoration: none;
  position: relative;
  padding-bottom: 0.33rem;
  transition: color 0.5s ease;

  &:hover {
    color: var(--brown);

    &::before {
      transform: scaleX(1);
    }
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--brown);
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }
`;

const UlMobile = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 70%;
  position: absolute;
  right: 0.05rem;
  width: 100vw;
  padding: 1.5rem 0;
  background-color: var(--background);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 0.5rem 0.5rem;

  li {
    display: inline-block;
    margin: 0 2rem;
  }

  a {
    text-decoration: none;
    color: var(--headline);
  }

  .button {
    color: var(--background);
  }

  @media only screen and (min-width: 1080px) {
    display: none;
  }
`;

export default function NavBar() {
  const [open, setOpen] = useState(false);

  return (
    <Navbar>
      <Link to="/">
        <Div>
          <StaticImage
            src="../images/avatarIcon.png"
            alt="Icon"
            layout="fixed"
            width={45}
            placeholder="tracedSVG"
          />
          <h3>Mateo Filip</h3>
        </Div>
      </Link>

      {open ? (
        <UlMobile>
          <li key={'AboutMobile'}>
            <a href="#About">About</a>
          </li>

          <li key={'WorkMobile'}>
            <a href="#Work">Work</a>
          </li>

          <li key={'ContactMobile'}>
            <a href="mailto:mateofernandofilip@gmail.com">Let's Talk!</a>
          </li>
        </UlMobile>
      ) : null}

      {open ? (
        <span>
          <FiX onClick={() => setOpen(!open)} />
        </span>
      ) : (
        <span>
          <FiMenu onClick={() => setOpen(!open)} />
        </span>
      )}

      <Ul>
        <Li key={'About'}>
          <A href="#About">About</A>
        </Li>

        <Li key={'Work'}>
          <A href="#Work">Work</A>
        </Li>

        <Li key={'Contact'}>
          <a className="button" href="mailto:mateofernandofilip@gmail.com">
            Let's Talk!
          </a>
        </Li>
      </Ul>
    </Navbar>
  );
}
