import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const Section = styled.section`
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media only screen and (min-width: 768px) {
    min-height: 100vh;
    align-items: center;
  }

  @media only screen and (min-width: 1080px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
  }

  button {
    margin: 2rem 0;

    animation: bounceButton 1s infinite;
    @keyframes bounceButton {
      0%,
      100% {
        transform: translateY(-25%);
        animationtimingfunction: cubic-bezier(0.8, 0, 1, 1);
      }
      50% {
        transform: translateY(0);
        animationtimingfunction: cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`;

const DivIconContainer = styled.div`
  display: none;

  img {
    border-radius: 50%;
  }

  @media only screen and (min-width: 1080px) {
    display: block;
  }
`;

const Span = styled.span`
  color: var(--brown);
`;

const H1 = styled.h1`
  font-size: 5em;
  margin: 0;
`;

const P = styled.p`
  color: var(--grey);
  font-size: 1.25em;

  @media only screen and (min-width: 1024px) {
    padding-right: 15rem;
  }
`;

export default function Presentation() {
  return (
    <Section>
      <div>
        <P>
          <Span>Hello world! My name is...</Span>
        </P>
        <H1>Mateo Filip</H1>
        <P>
          ... and I'm a frontend developer based in Córdoba, Argentina, building
          the things in the web that you <Span>love</Span> to see.
        </P>
        <a
          href="mailto:mateofernandofilip@gmail.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button className="button">Say Hi!</button>
        </a>
      </div>

      <DivIconContainer>
        <StaticImage
          src="../images/avatarIconTurned.png"
          alt="Icon"
          placeholder="tracedSVG"
          loading="eager"
        />
      </DivIconContainer>
    </Section>
  );
}
