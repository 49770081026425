import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FiGithub, FiLink2 } from 'react-icons/fi';
import styled from 'styled-components';

const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(20rem + 2vw), 22rem);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  transition: ease-in-out 0.5s box-shadow;

  &:hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  }

  img {
    max-width: 100%;
    display: block;
    object-fit: cover;
    aspect-ratio: 16 / 9;
  }

  .cardBody {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h1 {
      font-size: 1.5rem;
      text-transform: capitalize;
      margin: 0;
    }

    p {
      font-weight: 400;
    }
  }

  ul {
    padding: 0;

    li {
      display: inline-block;
      align-self: flex-start;
      font-size: 0.75em;
      padding-right: 1.25rem;
      color: var(--brown);
      font-family: 'Roboto Mono', monospace;
      font-weight: 400;
    }
  }

  .cardFooter {
    display: flex;
    padding: 1rem;
    margin-top: auto;

    a {
      padding: 0.5rem;
    }

    .Icon {
      font-size: 1.5em;
      color: var(--brown);
      transition: 0.5s color ease-in-out, 0.25s transform;

      &:hover {
        cursor: pointer;
        color: var(--browner);
        transform: scale(1.25);
      }
    }
  }
`;

export default function Projects({
  imageData,
  title,
  description,
  technologies,
  github,
  link,
}) {
  return (
    <ProjectCard>
      <div className="cardHeader">
        <GatsbyImage image={imageData} alt={title} />
      </div>

      <div className="cardBody">
        <ul>
          {technologies.map((technology) => (
            <li key={technology}>{technology}</li>
          ))}
        </ul>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>

      <footer className="cardFooter">
        <a
          href={github}
          target="_blank"
          aria-label="GitHub"
          rel="noreferrer noopener"
        >
          <FiGithub className="Icon" />
        </a>
        <a
          href={link}
          target="_blank"
          aria-label="Web"
          rel="noreferrer noopener"
        >
          <FiLink2 className="Icon" />
        </a>
      </footer>
    </ProjectCard>
  );
}
