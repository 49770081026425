import React from 'react';

import ProjectCard from './ProjectCard';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const Section = styled.section`
  padding: 3rem 0;
  margin: 0 -2.2rem;
  text-align: left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;

    @media only screen and (min-width: 768px) {
      justify-content: flex-start;
    }

    h2 {
      margin: 0;

      &::before {
        content: '›';
        color: var(--brown);
        margin-right: 0.5rem;
      }
    }

    span {
      display: inline-block;
      margin-left: 1rem;
      width: 20%;
      height: 2px;
      background: var(--brown);

      @media only screen and (min-width: 768px) {
        width: 50%;
      }
    }
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin-block: 2rem;
    gap: 2rem;
  }
`;

export default function Projects() {
  const data = useStaticQuery(graphql`
    {
      allProjectsJson {
        edges {
          node {
            title
            description
            technologies
            github
            link
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const projects = data.allProjectsJson.edges;

  return (
    <Section id="Work" className="Work">
      <div className="titleContainer">
        <h2>Some of My Work</h2>
        <span></span>
      </div>

      <div className="cardContainer">
        {projects.map(({ node: project }) => {
          const title = project.title;
          const description = project.description;
          const technologies = project.technologies;
          const github = project.github;
          const link = project.link;
          const imageData = project.image.childImageSharp.gatsbyImageData;

          return (
            <ProjectCard
              imageData={imageData}
              title={title}
              description={description}
              technologies={technologies}
              github={github}
              link={link}
            />
          );
        })}
      </div>
    </Section>
  );
}
