import { createGlobalStyle } from 'styled-components';
import InterWoff from './Inter-Regular.woff';
import InterWoff2 from './Inter-Regular.woff2';
import RobotoMono from './RobotoMono-Regular.ttf';

const FontStyles = createGlobalStyle`
@font-face {
  font-family: 'Inter';
  src: url(${InterWoff2}) format('woff2'),
       url(${InterWoff}) format('woff');
}

@font-face {
    font-family: 'Roboto Mono';
    src: url(${RobotoMono}) format('woff2')
}
`;

export default FontStyles;
