import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const Section = styled.section`
  text-align: left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1080px) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  div {
    @media only screen and (min-width: 1080px) {
      width: 75%;
    }
  }

  .titleContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    @media only screen and (min-width: 768px) {
      margin-top: 2rem;
      justify-content: flex-start;
    }

    h2 {
      margin: 0;

      &::before {
        content: '›';
        color: var(--brown);
        margin-right: 0.5rem;
      }
    }

    span {
      display: inline-block;
      margin-left: 1rem;
      width: 50%;
      height: 2px;
      background: var(--brown);
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0;
    column-gap: 1rem;

    li {
      display: inline-block;
      color: var(--grey);
      font-weight: 400;

      &::before {
        content: '›';
        color: var(--brown);
        margin-right: 0.5rem;
      }
    }
  }

  a {
    display: inline-block;
    display: grid;
    justify-content: center;
  }

  .imageContainer {
    display: grid;
    place-items: center;

    img {
      margin: auto;
      width: 100%;
      border-radius: 15px;

      @media only screen and (min-width: 768px) {
        width: 75%;
      }
    }
  }
`;

export default function About() {
  return (
    <Section id="About" className="About">
      <div className="imageContainer">
        <StaticImage
          src="../images/profilePicture.jpg"
          alt="Myself"
          placeholder="blurred"
          loading="eager"
        />
      </div>

      <div>
        <div className="titleContainer">
          <h2>Who am I?</h2>
          <span></span>
        </div>

        <p>
          I’m a front-end web developer from Argentina, who enjoys the creation
          of good-looking content living on the internet. I have always been
          passionate about technology and ever since I finished high school I
          began to learn how to code right at college. Recently, I graduated
          from a full-stack coding Bootcamp and want to start developing my
          career in the tech world.
        </p>

        <p>
          An Empathic, enthusiastic person, challenge-lover, and someone that
          always wants something new to learn about, I love to take collective
          property of the project that I’m working on and make sure that the
          result is the best one that it could’ve been.
        </p>

        <p>
          Whenever I’m not coding, you can find me either playing a little
          videogame or reading whichever book I find worthy of my time.
        </p>

        <p>Some of the technologies I've been working with recently are...</p>

        <ul>
          <li key={'JavaScript (ES6)'}>JavaScript (ES6)</li>
          <li key={'React'}>React</li>
          <li key={'GraphQL'}>GraphQL</li>
          <li key={'Node.js'}>Node.js</li>
          <li key={'Express.js'}>Express.js</li>
          <li key={'MongoDB'}>MongoDB</li>
        </ul>

        <a
          href="/resume.pdf"
          target="_blank"
          rel="noreferrer noopener"
          className="button"
        >
          See my resume!
        </a>
      </div>
    </Section>
  );
}
