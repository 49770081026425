import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import Head from './Head';
import Fade from 'react-reveal/Fade';
import NavBar from './NavBar';
import Presentation from './Presentation';
import About from './About';
import Projects from './Projects';
import Footer from './Footer';
import styled from 'styled-components';
import 'animate.css';

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}

const MyHeader = styled.header`
  padding: 0 2rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10vh;

  @media only screen and (min-width: 768px) {
    padding: 0 5rem;
    margin-bottom: 5vh;
  }

  @media only screen and (min-width: 1080px) {
    margin-bottom: 0;
  }
`;

const MyFooter = styled.footer`
  padding: 1rem 3.33rem;

  @media only screen and (min-width: 768px) {
    padding: 1rem 15rem;
  }

  @media only screen and (min-width: 1080px) {
    padding: 1rem 35rem;
  }
`;

const Main = styled.main`
  padding: 0 2rem;

  @media only screen and (min-width: 768px) {
    padding: 0 5rem;
  }

  @media only screen and (min-width: 1080px) {
    padding: 0 10rem;
  }
`;

export default function Layout() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      <Head />

      {loading ? (
        <Loader />
      ) : (
        <>
          <MyHeader className="animate__animated animate__fadeInDown">
            <NavBar />
          </MyHeader>

          <Main className="animate__animated animate__fadeInUp animate__delay-1s">
            <Presentation />
            <Fade>
              <About />
            </Fade>
            <Fade>
              <Projects />
            </Fade>
          </Main>

          <MyFooter>
            <Footer />
          </MyFooter>
        </>
      )}
    </>
  );
}
